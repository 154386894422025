// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../font/Vazir-FD.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../font/Vazir-FD.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../font/Vazir-FD.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../font/Caveat/Caveat-VariableFont_wght.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../font/Nunito/Nunito-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\n\tfont-family: \"vazir\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"eot\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\"); /* Saf3\u00145, Chrome4+, FF3.5, Opera 10+ */\n}\n\n@font-face {\n\tfont-family: \"Caveat\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n}\n\n@font-face {\n\tfont-family: \"Nunito\";\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");\n}\n* {\n\tdirection: ltr;\n\tfont-family: Nunito;\n\ttext-align: left;\n}\nhtml {\n\tbackground-color: #f2f2f2;\n}\nbody {\n\tbackground: #f2f2f2;\n\tmargin: 0;\n\tfont-family: Vazir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',\n\t\t'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n\toverflow-x: hidden;\n}\n\ncode {\n\tfont-family: Vazir, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n::-webkit-scrollbar {\n\twidth: 10px;\n}\n\n::-webkit-scrollbar-track {\n\tbackground: #f1f1f1;\n}\n\n::-webkit-scrollbar-thumb {\n\tbackground: #67647e;\n}\n\n::-webkit-scrollbar-thumb:hover {\n\tbackground: rgb(85, 85, 85);\n}\n", ""]);
// Exports
module.exports = exports;
