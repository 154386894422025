// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../Static/img/pattern.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".education-main-container {\n\tbackground-image: radial-gradient(\n\t\t\tcircle farthest-corner at 12.3% 19.3%,\n\t\t\trgb(85, 88, 218, .75) 0%,\n\t\t\trgba(95, 209, 249, .75) 100.2%\n\t\t),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n\tposition: relative;\n\tpadding-top: 200px;\n\tpadding-bottom: 250px;\n}\n.education-title {\n\ttext-align: center;\n\tcolor: #f2f2f2;\n\tfont-weight: bold;\n\tmargin-bottom: 35px;\n}\n.edication-container {\n\twidth: 80%;\n\tmargin: 0px auto;\n}\n.education-section-title {\n\ttext-align: center;\n}\n.education-section-title p {\n\tbackground-color: #67647e;\n\tdisplay: inline-block;\n\tpadding: 50px 30px;\n\tmargin: 0px auto;\n\tborder-radius: 50%;\n\tfont-weight: bold;\n\tcolor: #f2f2f2;\n\tborder: 3px solid #f2f2f2;\n\tfont-size: 20px;\n\tbox-shadow: 0px 3px 8px -3px rgba(31, 30, 31, 1);\n}\n.education-section-title1 {\n\ttext-align: center;\n}\n.education-section-title1 p {\n\tbackground-color: #67647e;\n\tdisplay: inline-block;\n\tpadding: 50px 38px;\n\tmargin: 0px auto;\n\tborder-radius: 100px;\n\tfont-weight: bold;\n\tcolor: #f2f2f2;\n\tborder: 3px solid #f2f2f2;\n\tfont-size: 20px;\n\tbox-shadow: 0px 3px 8px -3px rgba(31, 30, 31, 1);\n}\n.education-section-detail {\n\tbackground-color: #f2f2f2;\n\tpadding: 15px;\n\tborder-radius: 10px;\n\tcolor: #67647e;\n\tbox-shadow: 0px 3px 8px -3px rgba(31, 30, 31, 1);\n}\n.education-section-detail p {\n\tmargin: 0px !important;\n\ttext-align: center;\n\tfont-size: 18;\n\tfont-weight: bold;\n}\n@media only screen and (max-width: 992px) {\n    .education-main-container {\n        padding-bottom: 200px;\n    }\n}\n", ""]);
// Exports
module.exports = exports;
