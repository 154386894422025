// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../Static/img/pattern.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".footer-main-container {\n\tbackground-image: radial-gradient(\n\t\t\tcircle farthest-corner at 12.3% 19.3%,\n\t\t\trgb(85, 88, 218, .75) 0%,\n\t\t\trgba(95, 209, 249, .75) 100.2%\n\t\t),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n\tposition: relative;\n\tpadding-top: 300px;\n}\n.footer-main-container p {\n\tcolor: #f2f2f2;\n\tmargin-bottom: 0;\n\tpadding-bottom: 50px;\n\ttext-align: center;\n\tfont-size: 20px;\n}\n@media only screen and (max-width: 1125px) {\n\t.footer-main-container {\n\t\tpadding-top:250px;\n\t}\n}\n@media only screen and (max-width: 992px) {\n\t.footer-main-container {\n\t\tpadding-top:220px;\n\t}\n}\n@media only screen and (max-width: 850px) {\n\t.footer-main-container {\n\t\tpadding-top:180px;\n\t}\n}\n@media only screen and (max-width: 650px) {\n\t.footer-main-container {\n\t\tpadding-top:155px;\n\t}\n}\n@media only screen and (max-width: 500px) {\n\t.footer-main-container {\n\t\tpadding-top:130px;\n\t}\n}\n@media only screen and (max-width: 400px) {\n\t.footer-main-container {\n\t\tpadding-top:120px;\n\t}\n}\n\n", ""]);
// Exports
module.exports = exports;
