// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../Static/img/pattern.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../Static/img/persian.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../Static/img/english.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".languege-main-container {\n\tbackground-image: radial-gradient(\n\t\t\tcircle farthest-corner at 12.3% 19.3%,\n\t\t\trgb(85, 88, 218, .75) 0%,\n\t\t\trgba(95, 209, 249, .75) 100.2%\n\t\t),\n\t\turl(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n\tposition: relative;\n\tpadding-top: 200px;\n\tpadding-bottom: 250px;\n}\n.languege-title {\n\ttext-align: center;\n\tcolor: #f2f2f2;\n\tfont-weight: bold;\n\tmargin-bottom: 35px;\n}\n.language-container {\n\twidth: 80%;\n\tmargin: 0px auto;\n}\n.language-box {\n\tmargin: 0px auto;\n\theight: 200px;\n\tbackground-color: #f2f2f2;\n\tborder-radius: 10px;\n\tbox-shadow: 0px 3px 8px -3px rgba(31, 30, 31, 1);\n}\n.persian {\n\tposition: relative;\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n}\n.persian p,\n.english p {\n\tcolor: #67647e;\n\tmargin: auto;\n    font-size: 18px;\n    text-align: center;\n    display: block;\n    font-weight: bold;\n    background-color: #fefefede;\n    padding: 5px;\n    border-radius: 10px;\n}\n.english {\n\tposition: relative;\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n}\n.light-box {\n\tposition: absolute;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\ttop: 0;\n\tbackground-color: #f2f2f280;\n\tborder-radius: 10px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 20px;\n}\n.language-container h4{\n    color: #f2f2f2;\n}\n", ""]);
// Exports
module.exports = exports;
